import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Orgaentity } from '@dep/common/core-api/types/orgaentity.type';
import { RcpsPlayerPropertiesInput } from '@dep/common/device-api/types/rcps-player-properties-input.type';
import {
  Location as ILocation,
  IOrgaentity,
  IOrgaentityAll,
  IOrgaentityCarMediaStick,
  IOrgaentityClient,
  IOrgaentityCompany,
  IOrgaentityHandoverCompanion,
  IOrgaentityLocation,
  ListResponse,
  RoleRight,
  IOrgaentityTreeItem,
  IUserRoleList,
  IOrgaentityData,
} from '@dep/common/interfaces';
import { IForm } from '@dep/common/interfaces/support';
import {
  ProductComponentType,
  RenewsProduct,
  Windows11UpgradeProductIndentifier,
} from '@dep/common/shop-api/enums/product.enum';
import { RoleRight as ShopRoleRight } from '@dep/common/shop-api/enums/role-right.enum';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subscription } from 'rxjs';

import { getPlaylistInfo } from '@dep/frontend/model-playlist-mapping';
import { ClientHasSupportTicketFormPipe } from '@dep/frontend/pipes/client-has-support-ticket-form.pipe';
import { ClientIconColorPipe } from '@dep/frontend/pipes/client-icon-color.pipe';
import { ClientIsIOTAssignedPipe } from '@dep/frontend/pipes/client-is-iot-assigned.pipe';
import { ClientIsRenewablePipe } from '@dep/frontend/pipes/client-is-renewable.pipe';
import { ClientIsUpgradeableToWindows11Pipe } from '@dep/frontend/pipes/client-is-upgradeable-to-windows11.pipe';
import { OrgaentityDataPipe } from '@dep/frontend/pipes/orgaentity-data.pipe';
import { UserHasRightPipe } from '@dep/frontend/pipes/user-has-right.pipe';
import { PopupBillingAddressComponent } from '@dep/frontend/popups/billing-address/billing-address-popup.component';
import { PopupEditClientsComponent } from '@dep/frontend/popups/edit-clients/edit-clients.component';
import { PopupEditDealerInformationComponent } from '@dep/frontend/popups/edit-dealer-information/edit-dealer-information.component';
import { PopupEnergyManagementComponent } from '@dep/frontend/popups/energy-management/energy-management.component';
import { PopupGenericMessageComponent } from '@dep/frontend/popups/generic-message/generic-message.component';
import { PopupPlayerRestartComponent } from '@dep/frontend/popups/player-restart/player-restart.component';
import { PopupProductChooserComponent } from '@dep/frontend/popups/product-chooser/product-chooser.component';
import { PopupRCPSSettingsComponent } from '@dep/frontend/popups/rcps-settings/rcps-settings.component';
import {
  PopupWindows11UpgradeProductChooserComponent,
} from '@dep/frontend/popups/windows11-upgrade-product-chooser/windows11-upgrade-product-chooser.component';
import { LocationsService } from '@dep/frontend/services/locations.service';
import { OneMirrorLicense, OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';
import { IPopupSettings, PopupService, PopupSize } from '@dep/frontend/services/popup.service';
import { TranslationService } from '@dep/frontend/services/translation.service';
import { UserService } from '@dep/frontend/services/user.service';
import { ProductModel as ShopProductModel } from '@dep/frontend/shop/models/product.model';
import { BasketService } from '@dep/frontend/shop/services/basket.service';
import { ProductsService } from '@dep/frontend/shop/services/products.service';
import { IProductComponentProduct } from '@dep/frontend/shop/tmp-utilities/shop-api/interfaces/product.interface';
import { SupportFormsService } from '@dep/frontend/support/services/supportforms.service';
import { environment } from 'src/environments/environment';
import { IOrderProduct } from '@dep/frontend/shop/tmp-utilities/shop-api/interfaces/order.interface';

/* eslint max-lines: off */
// TODO: Reduce lines of this component and remove the line above.

enum ClientType {
  Company = 'COMPANY',
  Location = 'LOCATION',
  Client = 'CLIENT',
  CarshowAdapter = 'CARSHOWADAPTER',
  Matchmaker = 'MATCHMAKER',
  HandoverCompanion = 'HANDOVERCOMPANION',
  CarMediaStick = 'CARMEDIASTICK',
  ShowroomConnect = 'SHOWROOMCONNECT',
  None = '',
}

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss'],
  providers: [
    ClientIsIOTAssignedPipe,
    ClientIconColorPipe,
    ClientIsRenewablePipe,
    ClientIsUpgradeableToWindows11Pipe,
    ClientHasSupportTicketFormPipe,
    OrgaentityDataPipe,
    UserHasRightPipe,
  ],
})
export class ClientsListComponent implements OnInit, OnDestroy {
  public readonly enumOneMirrorLicense = OneMirrorLicense;
  private static readonly ORGENTITY_TYPES_ORDER = [
    ['', 'COMPANY', 'LOCATION'], // These nodes should be treated as the same type regarding the order.
    'SHOWROOMCONNECT',
    'MATCHMAKER',
    'HANDOVERCOMPANION',
    'CLIENT',
    'CARSHOWADAPTER',
  ];

  /** Show or hide OneMirror column. */
  public readonly IS_ONEMIRROR_COLUMN_VISIBLE = false; // TODO: Currently not used and should be re-evaluated when the licenses module is done.
  public readonly IS_WINDOWS11_FEATURE_VISIBLE = environment.stage !== 'prod';
  public readonly ClientType = ClientType;
  public readonly RoleRight = RoleRight;
  public readonly ShopRoleRight = ShopRoleRight;

  public readonly ACTION_DROPDOWN_ITEMS = [
    {
      name: 'UPGRADE_WINDOWS11',
      label: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.UPGRADE_WINDOWS11'),
      icon: 'windows11',
      disabled: !this.IS_WINDOWS11_FEATURE_VISIBLE,
    },
    {
      name: 'RENEW_LICENSES',
      label: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.RENEW_LICENSES'),
      icon: 'key_white',
      disabled: false,
    },
  ] satisfies { name: 'UPGRADE_WINDOWS11' | 'RENEW_LICENSES'; label: string; icon: string; disabled: boolean; }[];

  public isLoading = false;
  public isLoadingRenew = false;
  public error?: string;
  public userRoles?: IUserRoleList[];
  private tree?: Array<IOrgaentity | IOrgaentityCompany | IOrgaentityLocation | IOrgaentityClient | IOrgaentityCarMediaStick>;
  public flatTree: Array<IOrgaentityTreeItem> = [];
  private flatTreeUnfiltered: IOrgaentityTreeItem[] = [];
  /** Ancestors of the local root nodes of the user. Required to determine inherited flags like `ignoreBillingAddressWarnings`. */
  public ancestors?: Orgaentity[];
  public pencilElementPosition: Array<number> = [];
  @Input() public embedded = false;
  public searchTermInput = '';
  public hideAlreadyRenewed = false;
  public hideEmpty = false;
  /** Whether at least one player is selected or not. */
  public isAnyPlayerSelected = false;

  private routeParamsSub$?: Subscription;
  /** List of support ticket forms that user can access */
  public supportForms: IForm[] = [];

  /** Stores which clients tree index element's context menu is visible right now. */
  public contextMenuVisibilityIndex?: number;
  public iconPressed = false;
  public menuIconPosLeft?: number;
  public menuIconPosTop?: number;
  private locationPath?: string;
  private formId?: number;
  public rowId?: number;

  public translations: Record<string, string> = {};

  public showModal = false;
  public showSubscriptionModal = false;
  public subscriptionModalOeId?: number;
  public subscriptionModalLocationName?: string;
  public modalTitle = '';
  public modalContent = '';
  public onModalDismissFn = (): void => { };

  constructor(
    public orgaentitiesService: OrgaentitiesService,
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private ngxTranslate: TranslateService,
    private translationService: TranslationService,
    private toastr: ToastrService,
    private popupService: PopupService,
    private userService: UserService,
    private shopProductsService: ProductsService,
    private shopBasketService: BasketService,
    private locationsService: LocationsService,
    private supportFormsService: SupportFormsService,
    private clientIconColorPipe: ClientIconColorPipe,
    private clientIsRenewablePipe: ClientIsRenewablePipe,
    private clientIsUpgradeableToWindows11Pipe: ClientIsUpgradeableToWindows11Pipe,
  ) {
    this.loadTranslations();
  }

  public async ngOnInit(): Promise<void> {
    this.routeParamsSub$ = this.route.params.subscribe((params) => {
      if (params.searchinput) {
        this.logger.debug('Found search input in param. Search input:', params.searchinput);
        this.searchTermInput = params.searchinput;
      }
    });

    this.loadClientsTree();

    // Using `.then()` to keep the different calls non-blocking.
    this.userService.getRoles().then((roles) => {
      this.userRoles = roles.items;
    });

    this.supportFormsService.getForms().then((forms) => {
      this.supportForms = forms.filter((form) => form.type !== 'HIDDEN');
    });
  }

  public ngOnDestroy(): void {
    this.routeParamsSub$?.unsubscribe();
  }

  private loadTranslations(): void {
    this.translationService.get([
      'ADMIN_PAGES.CLIENTS.LIST.VERSION',
      'ADMIN_PAGES.CLIENTS.LIST.UNKNOWN_STATE',
      'ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED',
      'ADMIN_PAGES.CLIENTS.LIST.CONNECTED',
      'ADMIN_PAGES.CLIENTS.LIST.DISCONNECTED',
      'ADMIN_PAGES.CLIENTS.LIST.NO_ELIGIBLE_PLAYERS',
      'ADMIN_PAGES.CLIENTS.LIST.TO_BASKET_TEXT_SELECT_PLAYER',
      'ADMIN_PAGES.CLIENTS.LIST.ERROR_TOO_MANY_RESULTS',
      'ADMIN_PAGES.CLIENTS.LIST.OK',
      'ADMIN_PAGES.CLIENTS.LIST.CRITICAL',
      'ADMIN_PAGES.CLIENTS.LIST.WARNING',
      'ADMIN_PAGES.CLIENTS.LIST.UNKNOWN',
      'ADMIN_PAGES.CLIENTS.LIST.RUNNING',
      'ADMIN_PAGES.CLIENTS.LIST.UNSTABLE',
      'ADMIN_PAGES.CLIENTS.LIST.VALID_UNTIL',
      'ADMIN_PAGES.CLIENTS.LIST.FREE',
    ]).subscribe({
      next: (texts) => {
        this.translations = texts;
      },
    });
  }

  private async loadClientsTree(): Promise<void> {
    this.isLoading = true;
    this.error = undefined;

    let tree: ListResponse<IOrgaentityAll>;
    try {
      tree = await this.orgaentitiesService.getClientsTree(undefined, this.searchTermInput);
    } catch (err: any) {
      const errMsg = err.message ?? (err.errors && err.errors.length ? err.errors[0].message : 'Unknown');
      this.logger.error('Getting clients failed', errMsg);

      if (errMsg.indexOf('exceeded maximum allowed payload size') >= 0) {
        this.error = this.translations['ADMIN_PAGES.CLIENTS.LIST.ERROR_TOO_MANY_RESULTS'];
      } else {
        this.error = errMsg;
      }
      this.tree = undefined;
      this.flatTreeUnfiltered = [];
      this.flatTree = [];
      this.isLoading = false;
      return;
    }

    if (!this.searchTermInput) {
      this.locationPath = tree.items[0].path;
      this.tree = tree.items;
    }

    this.sortTree(this.tree);
    this.flatTreeUnfiltered = this.treeToTreeItems(tree.items, []);
    this.flatTreeUnfiltered = this.setTreeParents(this.flatTreeUnfiltered);
    this.flatTree = this.flatTreeUnfiltered;
    this.isLoading = false;

    if (this.tree) {
      this.ancestors = await this.fetchAllAncestors(this.tree);
    }
  }

  private containsEnabledClient(ti: IOrgaentityTreeItem, all: IOrgaentityTreeItem[]): boolean {
    if (ti.item.type === 'CLIENT') {
      return !ti.disabled;
    }

    for (const currChildId of ti.children) {
      if (this.containsEnabledClient(all[currChildId], all)) {
        return true;
      }
    }

    return false;
  }

  private async fetchAllAncestors(tree: NonNullable<typeof this.tree>): Promise<Orgaentity[]> {
    const ancestorsPromises = tree.map((treeItem) => this.orgaentitiesService.getAncestorNodes(treeItem.id));
    const ancestorsResults = await Promise.all(ancestorsPromises);
    const ancestorsFlat = ancestorsResults.flat(1);

    return ancestorsFlat;
  }

  /**
   * Recursive function to transform Tree to Flat Tree items.
   */
  private treeToTreeItems(
    tree: Array<IOrgaentity | IOrgaentityCompany | IOrgaentityLocation | IOrgaentityClient | IOrgaentityHandoverCompanion>,
    flatTree: IOrgaentityTreeItem[],
    level = 0,
    parentId = 0,
    parentFlatId?: number,
    initVisible = true,
  ): IOrgaentityTreeItem[] {
    let initExpanded = initVisible;
    if (tree.length > 4) {
      initExpanded = false;
    }
    let newFlatTree = flatTree;
    for (const item of tree) {
      const isExpandableClient = item.type === 'CLIENT' || item.type === 'CARMEDIASTICK';
      /** Node could have children, but since the level below has not been loaded, we do not know yet. */
      const mightHasChildren = item.children === null;
      /** Node definitely does not have any children. */
      const doesNotHaveChildren = item.children && item.children.length === 0;
      let expanded = true;
      if (mightHasChildren || isExpandableClient) {
        expanded = false;
      } else if (!doesNotHaveChildren) {
        expanded = initExpanded;
      }

      newFlatTree.push({
        parentId,
        parentFlatId,
        level,
        item,
        visible: initVisible,
        expanded,
        selected: this.isPlayerWithoutLicense(item as IOrgaentityClient) ? undefined : false,
        disabled: false,
        children: [],
        infoExpanded: false,
      });
      if (!this.clientIsRenewablePipe.transform(newFlatTree[newFlatTree.length - 1].item)) {
        newFlatTree[newFlatTree.length - 1].selected = undefined;
      }
      if (item.children) {
        newFlatTree = this.treeToTreeItems(item.children, newFlatTree, level + 1, item.id, newFlatTree.length - 1, initExpanded);
      }
    }
    return newFlatTree;
  }

  private setTreeParents(items: IOrgaentityTreeItem[]): IOrgaentityTreeItem[] {
    for (let i = 0; i < items.length; i++) {
      const ids = [];
      for (let j = 0; j < items.length; j++) {
        if (items[j].parentFlatId === i) {
          ids.push(j);
        }
      }
      items[i].children = ids;
    }
    return items;
  }

  /**
   * Recursively sorts the input `tree` elements by `type` and `name`.
   *
   * @param tree (WARNING: Input will be modified by this function)
   */
  private sortTree(tree: Array<IOrgaentity | IOrgaentityCompany | IOrgaentityLocation | IOrgaentityClient | IOrgaentityCarMediaStick> | undefined): void {
    if (!tree) {
      // Sorting done.
      return;
    }

    tree.sort((row1, row2) => {
      const orgaentityType1 = ClientsListComponent.ORGENTITY_TYPES_ORDER.findIndex(
        // If the array item is an array, check if the type is in the nested array.
        (type) => (Array.isArray(type) && type.indexOf(String(row1.type)) >= 0)
          // If the array item is a string, just compare it to the row's type.
          || (typeof type === 'string' && type === row1.type),
      );
      const orgaentityType2 = ClientsListComponent.ORGENTITY_TYPES_ORDER.findIndex(
        (type) => (Array.isArray(type) && type.indexOf(String(row2.type)) >= 0)
          || (typeof type === 'string' && type === row2.type),
      );

      const name1 = row1.name.toLowerCase();
      const name2 = row2.name.toLowerCase();

      // If both `row1` and `row2` are included in the orgaentity types order list, sort based on their index in this list.
      if (orgaentityType1 !== -1 && orgaentityType2 !== -1) {
        if (orgaentityType1 === orgaentityType2) {
          // Sort players with the same orgaentity type alphabetically by name.
          return name1.localeCompare(name2);
        }
        return orgaentityType1 - orgaentityType2;
      }
      if (orgaentityType1 !== -1) {
        return -1; // `row1` will be in front of `row2`.
      }
      if (orgaentityType2 !== -1) {
        return 1; // `row2` will be in front of `row1`.
      }

      return name1.localeCompare(name2);
    });

    // Sort children.
    for (const treeItem of tree) {
      if (treeItem.children) {
        this.sortTree(treeItem.children);
      }
    }
  }

  public toggleRow(item: IOrgaentityTreeItem, event: MouseEvent): void {
    // Prevents function call when user clicks on checkbox.
    if ((event.target as HTMLElement).tagName === 'INPUT') {
      return;
    }

    // Do not try to toggle a row that cannot cannot have child elements.
    // Only unknown (""), "COMPANY", "LOCATION" can have children.
    if (!['', 'COMPANY', 'LOCATION'].includes(String(item.item.type ?? ''))) {
      return;
    }

    if (item.expanded) {
      item.expanded = false;
      this.hideRowsBelow(item);
    } else {
      item.expanded = true;
      this.showRowsBelow(item);
    }
  }

  private hideRowsBelow(item: IOrgaentityTreeItem): void {
    const items = this.flatTree.filter((ft) => ft.parentId === item.item.id);
    for (const ftItem of items) {
      ftItem.visible = false;
      this.hideRowsBelow(ftItem);
    }
  }

  private async showRowsBelow(item: IOrgaentityTreeItem, firstCall = true): Promise<void> {
    if (firstCall && item.item.children === null) {
      this.isLoading = true;
      try {
        const tree = await this.orgaentitiesService.getClientsTree(item.item.id);
        const subTree = tree.items[0];

        if (subTree.children) {
          const ftIndex = this.flatTree.findIndex((ft) => ft.item.id === item.item.id);

          this.sortTree(tree.items);
          let flatTreeUnfiltered = this.treeToTreeItems(tree.items, [], item.level, item.parentId, this.flatTree.length - 1);
          flatTreeUnfiltered = this.setTreeParents(flatTreeUnfiltered);
          this.flatTree.splice(ftIndex, 1, ...flatTreeUnfiltered);
        }
        this.isLoading = false;
      } catch (err) {
        this.logger.error('Showing rows below node failed', err);
        this.toastr.error(
          undefined,
          String(this.ngxTranslate.instant('ADMIN_PAGES.LOADING_FAILED')),
        );
        this.isLoading = false;
      }
    }

    if (item.expanded) {
      const items = this.flatTree.filter((ft) => ft.parentId === item.item.id);
      for (const ftItem of items) {
        ftItem.visible = true;
        this.showRowsBelow(ftItem, false);
      }
    }
  }

  public onRowSelected(item: IOrgaentityTreeItem, overrideSelected?: boolean): void {
    const selected = typeof overrideSelected === 'boolean' ? overrideSelected : item.selected;
    if (item.item.children) {
      for (const c of item.item.children) {
        const ti = this.getTreeItemById(c.id);
        if (!ti) {
          continue;
        }
        if (this.clientIsRenewablePipe.transform(ti.item) || this.clientIsUpgradeableToWindows11Pipe.transform(ti.item)) {
          ti.selected = selected;
          this.onRowSelected(ti, selected);
        }
      }
    }

    this.updateIsAnyPlayerSelected();
  }

  public isChildVisible(item: IOrgaentityTreeItem): boolean {
    if (item.item.children) {
      for (const c of item.item.children) {
        const x = this.flatTree.find((ft) => ft.item.id === c.id);
        if (x && x.visible && x.item.type === 'CLIENT') {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Update `isAnyPlayerSelected` by looking for a CLIENT whose row is `selected`.
   * If a selected player has been found, `isAnyPlayerSelected` is set to `true` and
   * the function terminates.
   */
  private updateIsAnyPlayerSelected(): void {
    for (const row of this.flatTree) {
      if (row.item.type === 'CLIENT' && row.selected) {
        this.isAnyPlayerSelected = true;
        return;
      }
    }
    this.isAnyPlayerSelected = false;
  }

  private getTreeItemById(id: number): IOrgaentityTreeItem | null {
    return this.flatTree.find((item) => id === item.item.id) || null;
  }

  /**
   * Expand the client row.
   * Called when the user clicks on the client row.
   */
  public async expandClient(event: MouseEvent, row: IOrgaentityTreeItem): Promise<void> {
    // Prevents function call when user click on checkbox,contextmenu icon,link and span with class prevent
    if ((event.target as HTMLElement).tagName === 'INPUT'
      || (event.target as HTMLElement).tagName === 'IMG'
      || (event.target as HTMLElement).tagName === 'A'
      || ((event.target as HTMLElement).tagName === 'SPAN' && (event.target as HTMLElement).classList.contains('prevent'))) {
      return;
    }
    row.expanded = !row.expanded;

    this.rowId = row.item.id;
  }

  public isExpired(validity: string): boolean {
    return new Date(validity).getTime() < new Date().getTime();
  }

  public onFiltersUpdate(searchTermInput: string): void {
    this.searchTermInput = searchTermInput;
    this.loadClientsTree();
  }

  public hideRenewedClients(): void {
    // First disable all clients which are already renewed.
    this.flatTree.forEach((item) => {
      if (item.item.type && item.item.type === 'CLIENT') {
        item.disabled = this.hideAlreadyRenewed ? !this.clientIsRenewablePipe.transform(item.item) : false;
      }
    });

    // Afterwards disable rows with no clients.
    this.hideEmptyRows();
  }

  public hideEmptyRows(): void {
    this.flatTree.forEach((item) => {
      if (item.item.type && item.item.type !== 'CLIENT') {
        item.disabled = (this.hideAlreadyRenewed || this.hideEmpty) ? !this.containsEnabledClient(item, this.flatTree) : false;
      }
    });
  }

  // TODO: Replace this with a variable that is updated after all `flatTree` `disabled` modifications.
  public flatTreeLength(): number {
    return this.flatTree.filter((ft) => !ft.disabled).length;
  }

  /**
   * Check if the client is a player without an RCPS license (no possibility to renew it),
   * which is the case for subtypes "Showroom:Presenter" (ID 2) and "Media Hop" (ID 3).
   *
   * Related names for "Showroom:Presenter": "Screenhost" (Display), "Sales Tablet" (iPad)
   */
  private isPlayerWithoutLicense(item: IOrgaentityClient): boolean {
    return (item.type === 'CLIENT' && item.orgaentitySubtypeId !== 1);
  }

  /**
   * Set up subscription modal and visibility.
   */
  public showSetupSubscriptionModal(row: IOrgaentityTreeItem): void {
    this.subscriptionModalLocationName = row.item.name;
    this.subscriptionModalOeId = row.item.id;
    this.showSubscriptionModal = true;
    this.onModalDismissFn = () => {
      this.showSubscriptionModal = false;
    };
  }

  /**
   * Processes the selected user action if a player is selected; otherwise, shows a popup.
   *
   * @param action - The action to be processed
   */
  public processAction(action: 'UPGRADE_WINDOWS11' | 'RENEW_LICENSES'): void {
    // Show info popup when the user did not select any players.
    if (!this.isAnyPlayerSelected) {
      const title = action === 'UPGRADE_WINDOWS11' ? 'WINDOWS11_MIGRATION_TITLE' : 'RENEW_LICENSES';
      const message = action === 'UPGRADE_WINDOWS11' ? 'WINDOWS11_MIGRATION_TEXT' : 'TO_BASKET_TEXT_SELECT_PLAYER';

      this.popupService.open(
        PopupGenericMessageComponent,
        {
          size: PopupSize.SMALL,
          customValues: {
            title: this.ngxTranslate.instant(`ADMIN_PAGES.CLIENTS.LIST.${title}`),
            message: this.ngxTranslate.instant(`ADMIN_PAGES.CLIENTS.LIST.${message}`),
          },
        },
        {
          okButton: () => {
            this.isLoadingRenew = false;
            this.isLoading = false;
          },
        },
      );
    } else {
      switch (action) {
        case 'UPGRADE_WINDOWS11': {
          // Categorize selected players based on their eligibility for the Windows 11 upgrade.
          // Eligible players are divided into two groups, while rest are added to ineligible players.
          const playersEligibility = this.flatTree
            .filter((item) => item.selected
              && item.item.type === 'CLIENT'
              && (
                this.orgaentitiesService.getWindows11MigrationOrderStatus(item.item) !== 'PAID'
                && this.orgaentitiesService.getWindows11MigrationOrderStatus(item.item) !== 'CREATED'
              ))
            .reduce((acc: { upgradeWithoutHardware: string[], upgradeWithHardware: string[], ineligible: string[] }, item) => {
              const playerWindows11ReadyState = this.orgaentitiesService.getWindows11MigrationReadyState(item.item);

              // If the player is eligible for Windows 11 upgrade, add it to the corresponding list.
              if (playerWindows11ReadyState.startsWith('ok') || playerWindows11ReadyState.startsWith('warning')) {
                acc.upgradeWithoutHardware.push((item.item as IOrgaentityClient).playername);
              } else if (playerWindows11ReadyState.startsWith('critical')) {
                acc.upgradeWithHardware.push((item.item as IOrgaentityClient).playername);
              } else if (playerWindows11ReadyState !== 'upgraded') {
                acc.ineligible.push((item.item as IOrgaentityClient).playername);
              }

              return acc;
            }, { upgradeWithoutHardware: [], upgradeWithHardware: [], ineligible: [] });

          const products = [
            {
              identifier: Windows11UpgradeProductIndentifier.Windows11Upgrade,
              description: 'WINDOWS11_UPGRADE_DESCRIPTION',
              selectedEligiblePlayers: playersEligibility.upgradeWithoutHardware.length ? playersEligibility.upgradeWithoutHardware : undefined,
            },
            {
              identifier: Windows11UpgradeProductIndentifier.Windows11UpgradeHardware,
              description: 'WINDOWS11_UPGRADE_HARDWARE_DESCRIPTION',
              selectedEligiblePlayers: playersEligibility.upgradeWithHardware.length ? playersEligibility.upgradeWithHardware : undefined,
            },
            {
              identifier: Windows11UpgradeProductIndentifier.Windows11UpgradeHardwareMI,
              description: 'WINDOWS11_UPGRADE_HARDWARE_MI_DESCRIPTION',
              selectedEligiblePlayers: playersEligibility.upgradeWithHardware.length ? playersEligibility.upgradeWithHardware : undefined,
            },
          ];

          const mbuiAlerts: { message: string; type: 'error' | 'warning' | 'info' | 'success'; }[] = [];

          if (playersEligibility.ineligible.length > 0) {
            const playersString = playersEligibility.ineligible.join(', ');
            mbuiAlerts.push(
              { message: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.MBUI_INELIGIBLE_PLAYERS_MESSAGE', { playersString }), type: 'warning' },
            );
          }

          if (playersEligibility.upgradeWithoutHardware.length > 0 && playersEligibility.upgradeWithHardware.length > 0) {
            mbuiAlerts.push({ message: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.MBUI_ADDITIONAL_ORDER_MESSAGE'), type: 'info' });
          }

          this.showWindows11UpgradeProductChooserPopup(
            {
              products: JSON.stringify(products),
              title: 'UPGRADE_WINDOWS11',
              mbuiAlerts: JSON.stringify(mbuiAlerts),
            },
          );
          break;
        }
        case 'RENEW_LICENSES': {
          const products = [{ name: RenewsProduct.DigitalSignage }];

          // Currently not in use, but should be re-evaluated when the licenses module is done.
          if (this.IS_ONEMIRROR_COLUMN_VISIBLE) {
            products.push({ name: RenewsProduct.OneMirror });
          }

          this.showProductChooserPopup({ products: JSON.stringify(products), title: 'LICENSE_OPTIONS' });
          break;
        }
        default: {
          this.logger.error('Invalid action value', action);
          throw new Error('Invalid action value');
        }
      }
    }
  }

  private showProductChooserPopup(customValues: Record<string, string>): void {
    this.popupService.open(
      PopupProductChooserComponent,
      {
        closeable: true,
        customValues,
      },
      {
        selectedProduct: (renewsProduct: RenewsProduct) => {
          this.logger.debug('Passing selected renews product to showToBasketModal', renewsProduct);
          this.initiateRenewLicensesProcess(renewsProduct);
        },
      },
    );
  }

  private showWindows11UpgradeProductChooserPopup(customValues: Record<string, string>): void {
    this.popupService.open(
      PopupWindows11UpgradeProductChooserComponent,
      {
        closeable: true,
        customValues,
      },
      {
        selectedProduct: (productIdentifier: Windows11UpgradeProductIndentifier) => {
          this.logger.debug('Passing selected product identifier to showToBasketModal', productIdentifier);
          this.initiateUpgradeWindows11Process(productIdentifier);
        },
      },
    );
  }

  /**
   * Initiates the process of renewing licenses for selected clients.
   *
   * This method first retrieves the applicable products for the specified renewal product type.
   * If exactly one applicable product is found, it proceeds to add the product to the basket and navigates to the basket page.
   *
   * @param renewsProduct - The type of product to renew licenses for
   */
  private async initiateRenewLicensesProcess(renewsProduct: RenewsProduct): Promise<void> {
    this.isLoadingRenew = true;
    this.isLoading = true;

    let applicableProducts: ShopProductModel[] | undefined;
    try {
      this.logger.debug('Getting applicable products for license renewal', renewsProduct);
      applicableProducts = (await this.shopProductsService.listSharedProductsOfShop())
        .filter((product: ShopProductModel) => product.settings.renewsProduct === renewsProduct);
    } catch (error: any) {
      this.logger.error('Getting applicable products failed', error);
      this.isLoadingRenew = false;
      this.isLoading = false;
    }

    // The shop must have exactly 1 license renewal product of the specified `renewsProduct`.
    if (applicableProducts && applicableProducts.length === 1) {
      const [applicableProduct] = applicableProducts;

      try {
        await this.addApplicableProductToBasket(applicableProduct);
      } catch (renewError) {
        // Show popup in case of an error while trying to renew licenses.
        this.popupService.open(
          PopupGenericMessageComponent,
          {
            size: PopupSize.SMALL,
            customValues: {
              title: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.RENEWAL_IMPOSSIBLE'),
              message: (renewError as Error).message === 'No eligible players selected'
                ? this.translations['ADMIN_PAGES.CLIENTS.LIST.NO_ELIGIBLE_PLAYERS']
                : (renewError as Error).message,
            },
          },
          {
            okButton: () => {
              this.isLoadingRenew = false;
              this.isLoading = false;
            },
          },
        );
      }
    } else {
      let popupTitle: string;
      let popupMessage: string;

      // Unexpected/invalid case.
      if (applicableProducts === undefined || applicableProducts.length > 1) {
        popupTitle = 'Error';
        popupMessage = 'Unable to proceed. Product configuration error.';
      } else { // If there is no (0) applicable product, license renewal is disabled for this shop.
        popupTitle = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.TO_BASKET_TITLE_REGISTRY_NOT_ALLOWED');
        popupMessage = this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.TO_BASKET_TEXT_REGISTRY_NOT_ALLOWED');
      }

      this.popupService.open(
        PopupGenericMessageComponent,
        {
          size: PopupSize.SMALL,
          customValues: {
            title: popupTitle,
            message: popupMessage,
          },
        },
        {
          okButton: () => {
            this.isLoadingRenew = false;
            this.isLoading = false;
          },
        },
      );
    }
  }

  /**
   * Initiates the process to order a Windows 11 upgrade.
   *
   * This method retrieves the applicable products for Windows 11 upgrade based on product identifier.
   * If an applicable product is found, it is added to the basket and the user is redirected to the basket page.
   *
   * @param productIdentifier - Identifier of the Windows 11 upgrade product
   */
  private async initiateUpgradeWindows11Process(productIdentifier: Windows11UpgradeProductIndentifier): Promise<void> {
    this.isLoadingRenew = true;
    this.isLoading = true;

    let applicableProducts: ShopProductModel[] | undefined;
    try {
      this.logger.debug('Getting applicable products for Windows 11 upgrade', productIdentifier);
      applicableProducts = (await this.shopProductsService.listSharedProductsOfShop())
        .filter((product: ShopProductModel) => product.identifier?.startsWith('WINDOWS11'));
    } catch (error: any) {
      this.logger.error('Getting applicable products failed', error);
      this.isLoadingRenew = false;
      this.isLoading = false;
    }

    if (!applicableProducts) {
      this.logger.error('There are no applicable products', applicableProducts);
      this.toastr.error(
        undefined,
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.NO_APPLICABLE_PRODUCTS')),
      );
      this.isLoadingRenew = false;
      this.isLoading = false;
      return;
    }

    const windows11UpgradeProduct = applicableProducts.filter((product) => product.identifier === Windows11UpgradeProductIndentifier.Windows11Upgrade);
    const windows11UpgradeWithHardwareProduct = applicableProducts.filter(
      (product) => product.identifier === Windows11UpgradeProductIndentifier.Windows11UpgradeHardware,
    );
    const windows11UpgradeWithHardwareMiProduct = applicableProducts.filter(
      (product) => product.identifier === Windows11UpgradeProductIndentifier.Windows11UpgradeHardwareMI,
    );

    // The shop can have 3 Windows 11 upgrade products (1 of each type: 'WINDOWS11_UPGRADE', 'WINDOWS11_UPGRADE_HARDWARE', 'WINDOWS11_UPGRADE_HARDWARE_MI').
    const isValidWindows11UpgradeConfiguration = windows11UpgradeProduct.length === 1
      && windows11UpgradeWithHardwareProduct.length === 1
      && windows11UpgradeWithHardwareMiProduct.length === 1;

    if (isValidWindows11UpgradeConfiguration) {
      const applicableProduct = applicableProducts.find((product) => product.identifier === productIdentifier);

      if (!applicableProduct) {
        this.logger.error('No applicable product for the selected product identifier found', productIdentifier);
        this.toastr.error(
          undefined,
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.NO_APPLICABLE_PRODUCT')),
        );
        this.isLoadingRenew = false;
        this.isLoading = false;
        return;
      }

      try {
        await this.addApplicableProductToBasket(applicableProduct);
      } catch (error) {
        // Show popup in case of an error while trying to upgrade the Windows.
        this.popupService.open(
          PopupGenericMessageComponent,
          {
            size: PopupSize.SMALL,
            customValues: {
              title: this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.LIST.RENEWAL_IMPOSSIBLE'),
              message: (error as Error).message === 'No eligible players selected'
                ? this.translations['ADMIN_PAGES.CLIENTS.LIST.NO_ELIGIBLE_PLAYERS']
                : (error as Error).message,
            },
          },
          {
            okButton: () => {
              this.isLoadingRenew = false;
              this.isLoading = false;
            },
          },
        );
      }
    } else { // Unable to proceed due to incorrect shop configuration.
      this.popupService.open(
        PopupGenericMessageComponent,
        {
          size: PopupSize.SMALL,
          customValues: {
            title: 'Error',
            message: 'Unable to proceed. Product configuration error.',
          },
        },
        {
          okButton: () => {
            this.isLoadingRenew = false;
            this.isLoading = false;
          },
        },
      );
    }
  }

  /**
   * Adds an applicable product to the basket and navigates to the basket page.
   *
   * @param applicableProduct - The product to be added to the basket
   */
  private async addApplicableProductToBasket(applicableProduct: ShopProductModel): Promise<void> {
    this.isLoadingRenew = true;
    this.isLoading = true;
    let inputGSSN: string | undefined = '';
    let locationDetails: ILocation | undefined;
    const componentProducts = applicableProduct.components.filter((component) => component.type === ProductComponentType.PRODUCT) as IProductComponentProduct[];

    try {
      const oeList = await this.locationsService.getLocations() || undefined;
      const clients = this.flatTree
        .filter((item) => !!item.selected
          && item.item.type === 'CLIENT'
          && (
            // If the product is a Windows 11 upgrade, only include clients without an existing order.
            !applicableProduct.identifier?.startsWith('WINDOWS11')
            || (
              this.orgaentitiesService.getWindows11MigrationOrderStatus(item.item) !== 'PAID'
              && this.orgaentitiesService.getWindows11MigrationOrderStatus(item.item) !== 'CREATED'
            )
          ))
        .filter((item) => {
          const playerWindows11ReadyState = this.orgaentitiesService.getWindows11MigrationReadyState(item.item);

          if (applicableProduct.identifier === Windows11UpgradeProductIndentifier.Windows11Upgrade) {
            // If it is a Windows 11 upgrade product, filter only the players that can already be upgraded.
            return playerWindows11ReadyState.startsWith('ok') || playerWindows11ReadyState.startsWith('warning');
          }
          if (applicableProduct.identifier?.startsWith('WINDOWS11_UPGRADE_HARDWARE')) {
            // If it is a Windows 11 upgrade hardware product, filter only the players that need a new hardware.
            return playerWindows11ReadyState.startsWith('critical');
          }
          // Otherwise, filter license renewable players.
          return this.shopProductsService.isLicenseRenewable(item.item, applicableProduct);
        });

      if (clients.length === 0) {
        throw new Error('No eligible players selected');
      }

      /** Hostnames of all clients which licenses should be renewed. */
      const hostnames: string[] = [];
      for (const client of clients) {
        if (client.selected) {
          const { playername } = client.item as IOrgaentityClient;
          hostnames.push(playername);
        }
      }

      const firstClientPath = clients.find((client) => client.selected)?.item.path;
      if (!firstClientPath) {
        this.logger.error('ClientsListComponent: First selected client does not have a path', clients.find((client) => client.selected), firstClientPath);
        throw new Error('First selected client is not configured correctly: No path');
      }

      // Get shop nodes and find the node responsible for the first client.
      const roles = await this.userService.getRoles();
      /** All of the user's roles that have the SHOP_ORDER_CREATE right. */
      const shopRoles = roles.items.filter((role) => role.role.rights.includes(ShopRoleRight.ShopOrderCreate));
      /** The paths of all of the user's roles that have the SHOP_ORDER_CREATE right. */
      const shopRolesOrgaentities = shopRoles.flatMap((val) => val.orgaentities);
      const shopNode = shopRolesOrgaentities.find((shopRolePath) => firstClientPath.startsWith(shopRolePath.path));
      this.logger.debug('ClientsListComponent: Determined shop node', shopNode);

      try {
        const firstClient = clients[0];
        // Traverse up and find COMPANY node to get company GSSN.
        if (firstClient.item.type !== 'COMPANY') {
          let currentNode: IOrgaentity | IOrgaentityCompany | IOrgaentityLocation | IOrgaentityClient | undefined = firstClient.item;
          let iterations = 0;
          let locationNodeGSSN: string | undefined;
          while (iterations < 100 && currentNode && currentNode.type !== 'COMPANY') {
            currentNode = this.getTreeItemById(currentNode.parentOrgaentityId || -1)?.item;
            if (!locationNodeGSSN && currentNode && currentNode.type === 'LOCATION') {
              locationNodeGSSN = currentNode.gssn;
            }
            ++iterations;
          }
          if (iterations > 99) {
            throw new Error('Infinite loop detected while traversing up to find company node');
          }

          inputGSSN = currentNode?.gssn || locationNodeGSSN;
        } else {
          inputGSSN = firstClient.item.gssn;
        }
      } catch (err) {
        this.logger.error(err);
        this.isLoadingRenew = false;
        this.isLoading = false;
        throw new Error('Could not find company node/gssn, unexpected error.');
      }

      // Get location details from OE list for selected GSSN.
      locationDetails = oeList?.items.find((oe) => oe.gssn === inputGSSN);

      this.shopBasketService.clearBasket();

      // pass the company gssn and playernames in basket extraData
      this.shopBasketService.extraData = {
        location: {
          gssn: inputGSSN,
          address: locationDetails,
        },
      };
      this.shopBasketService.basketMetadata.shopPath = shopNode?.path; // This will be used in the basket to determine the order's path.
      this.shopBasketService.basketMetadata.shopOEName = shopNode?.name;

      this.shopBasketService.setProduct(applicableProduct);

      for (const componentProduct of componentProducts) {
        let quantity = 1;
        const extraData: IOrderProduct['extraData'] = {};
        // If the product component has the `HOSTNAMES_DEPENDENT` price mode (e. g. for license renewals),
        // insert the hostnames and set the quantity to the amount of hostnames.
        if (componentProduct.product.price.modes.HOSTNAMES_DEPENDENT) {
          quantity = hostnames.length;
          extraData.hostnames = hostnames;
        }
        // Info: Other price modes like PER_PRODUCT are handled in the basket.

        this.shopBasketService.addOrderProduct({
          shopProductId: applicableProduct.id,
          shopProductVersion: applicableProduct.version,
          productName: componentProduct.product.name,
          quantity,
          totalPriceNet: (this.shopBasketService.calculatePeriodInMonthsPrice(componentProduct) * quantity),
          extraData,
        });
      }

      this.router.navigate(['/shop/basket']);
      this.isLoadingRenew = false;
      this.isLoading = false;
    } catch (e) {
      this.logger.error('Renewing licenses failed', e);
      this.isLoadingRenew = false;
      this.isLoading = false;
      throw e;
    }
  }

  public async downloadCSVExport(id: number): Promise<void> {
    this.isLoading = true;

    try {
      await lastValueFrom(this.http.get<void>(
        environment.config.apiGateway.url + '/orgaentities/export/' + id,
        {
          headers: await this.userService.getAuthorizationHeaders(),
        },
      ));

      this.showExportInfo();
      this.isLoading = false;
    } catch (err) {
      this.logger.error(err);
      this.isLoading = false;
    }
  }

  private showExportInfo(): void {
    this.modalTitle = 'Export initiated';
    this.modalContent = 'When the export is ready, you will receive an e-mail with a temporary download link.';
    this.showModal = true;
    this.onModalDismissFn = () => {
      this.showModal = false;
    };
  }

  /**
   * Open popup to edit an orgaentity.
   *
   * @param row - Organizational entity tree row item
   * @param orgaentityId - Organizational entity ID to be edited
   */
  public openOrgaentityEditPopup(row: IOrgaentityTreeItem, orgaentityId: number): void {
    const customValues = {
      clientId: String(orgaentityId),
      parentId: String(row.parentId),
      parentName: row.item.name,
    };
    this.openOrgaentityPopup(row, customValues);
  }

  /**
   * Open popup to create an orgaentity.
   *
   * @param row - Organizational entity tree row item
   */
  public openOrgaentityCreatePopup(row: IOrgaentityTreeItem): void {
    const customValues = {
      clientId: '-1',
      parentId: String(row.item.id),
      parentName: row.item.name,
    };
    this.openOrgaentityPopup(row, customValues);
  }

  /**
   * Open popup to create a Carshow:Adapter (CA).
   *
   * @param row - Organizational entity tree row item (where CA should be created)
   */
  public openCarshowAdapterCreatePopup(row: IOrgaentityTreeItem): void {
    const customValues = {
      clientId: 'carshow',
      parentId: String(row.item.id),
      parentName: row.item.name,
    };
    this.openOrgaentityPopup(row, customValues);
  }

  /**
   * Open popup to edit or create an orgaentity. Called by the specific method.
   * Contains the common popup settings, so that they do not have to be specified in each popup method.
   */
  private openOrgaentityPopup(row: IOrgaentityTreeItem, customValues: IPopupSettings['customValues']): void {
    this.popupService.open(
      PopupEditClientsComponent,
      {
        size: PopupSize.LARGE,
        customValues,
      },
      {
        onClientSave: (updatedValues: Pick<IOrgaentityClient, 'name' | 'gssn' | 'brandCode' | 'division'>) => {
          // If a new OE has been created at this node, (re)load all children.
          if (customValues?.clientId === '-1' || customValues?.clientId === 'carshow') {
            // Remove existing players with the same parent ID from the `flatTree` array to avoid player duplication.
            const existingPlayers = this.flatTree.filter((ft) => ft.parentId === row.item.id);
            existingPlayers.forEach((existingPlayer) => {
              const existingPlayerIndex = this.flatTree.findIndex((ft) => ft.item.id === existingPlayer.item.id);
              this.flatTree.splice(existingPlayerIndex, 1);
            });

            row.item.children = null;
            this.showRowsBelow(row);
          } else {
            // If an existing OE has been updated, just set the new name, gssn, and brand code.
            row.item.name = updatedValues.name;
            row.item.gssn = updatedValues.gssn;
            row.item.brandCode = updatedValues.brandCode;
            (row.item as IOrgaentityClient).division = updatedValues.division;
          }
        },
        onClientDelete: () => {
          // If an OE has been removed, remove it from the tree to reflect this change.
          this.flatTree = this.flatTree.filter((player) => player.item.id !== row.item.id);
        },
      },
    );
  }

  /**
   * Open popup to edit a RCPS settings.
   *
   * @param row - Organizational entity tree row item
   */
  public openRcpsSettingsPopup(row: IOrgaentityTreeItem): void {
    this.popupService.open(
      PopupRCPSSettingsComponent,
      {
        size: PopupSize.LARGE,
        customValues: {
          orgaentityId: String(row.item.id),
          playername: this.asClient(row.item).playername,
          name: this.asClient(row.item).name,
          clientColor: this.clientIconColorPipe.transform(row.item),
          clientAbbreviation: this.orgaentitiesService.getClientTypeAbbreviation(this.asClient(row.item).playername),
          canViewAndUpdateRetailerPlayerName: 'true',
          canViewAndUpdateHostname: 'true',
          canViewAndUpdateWorkgroup: 'true',
          canViewAndUpdateBandwidthThrottling: 'true',
        },
      },
      {
        onPropertiesSave: (properties: RcpsPlayerPropertiesInput) => {
          // After the changes were successfully saved, update the retailer player name in the cockpit tree.
          row.item.name = properties.name;
        },
      },
    );
  }

  /**
   * Open popup to edit a client's energy settings.
   *
   * @param row - Organizational entity tree row item
   */
  public openEnergyManagementPopup(row: IOrgaentityTreeItem): void {
    this.popupService.open(
      PopupEnergyManagementComponent,
      {
        size: PopupSize.LARGE,
        customValues: {
          orgaentityId: String(row.item.id),
          name: this.asClient(row.item).name,
          playername: this.asClient(row.item).playername,
          clientColor: this.clientIconColorPipe.transform(row.item),
          clientAbbreviation: this.orgaentitiesService.getClientTypeAbbreviation(this.asClient(row.item).playername),
          type: String(row.item.type),
        },
      },
    );
  }

  /**
   * Open popup to edit dealer information.
   *
   * @param row - Organizational entity tree row item
   */
  public openEditDealerInformationPopup(row: IOrgaentityTreeItem): void {
    this.popupService.open(
      PopupEditDealerInformationComponent,
      {
        size: PopupSize.LARGE,
        customValues: {
          orgaentityId: String(row.item.id),
          name: this.asClient(row.item).name,
        },
      },
    );
  }

  /**
   * Open popup to restart player.
   *
   * @param row - Organizational entity tree row item
   */
  public openPlayerRestartPopup(row: IOrgaentityTreeItem): void {
    this.popupService.open(
      PopupPlayerRestartComponent,
      {
        size: PopupSize.LARGE,
        customValues: {
          orgaentityId: String(row.item.id),
          playername: this.asClient(row.item).playername,
          clientColor: this.clientIconColorPipe.transform(row.item),
          clientAbbreviation: this.orgaentitiesService.getClientTypeAbbreviation(this.asClient(row.item).playername),
        },
      },
    );
  }

  public openBillingAddressUpdatePopup(row: IOrgaentityTreeItem): void {
    this.popupService.open(
      PopupBillingAddressComponent,
      {
        size: PopupSize.LARGE,
        customValues: {
          orgaentityId: String(row.item.id),
        },
      },
      {
        onBillingAddressSave(): void {
          const billingAddressIndicator: IOrgaentityData = { k: 'hasBillingAddress', v: 'true' };
          // Immediately update row data by adding or replacing the "hasBillingAddress" flag.
          row.item.data = row.item.data
            ? [
              ...row.item.data.filter((data) => data.k !== billingAddressIndicator.k),
              billingAddressIndicator,
            ]
            : [billingAddressIndicator];
        },
      },
    );
  }

  private asClient(obj: any): IOrgaentityClient {
    return obj;
  }

  /**
   * Updates RCPS-assigned content for the stage wall player upon successful change.
   *
   * @param changedEventObj - Object containing the orgaentity id and changed playlist name.
   */
  public onRcpsContentChanged(changedEventObj: { orgaentityId: number, playlistName: string }): void {
    this.logger.debug('Updating stage wall content after change', changedEventObj);
    const index = this.flatTree.findIndex((tree) => tree.item.id === changedEventObj.orgaentityId);
    this.flatTree[index].assignedContent = [changedEventObj.playlistName];
    this.flatTree[index].assignedContentModel = getPlaylistInfo(changedEventObj.playlistName)?.modelName;
  }

  /**
   * Open popup to choose product (Digital:Signage or Showroom:Presenter).
   *
   * @param obj - Organizational entity tree row item
   */
  private openProductChooserPopup(obj: IOrgaentityTreeItem): void {
    const products = [{ name: RenewsProduct.DigitalSignage }, { name: RenewsProduct.ShowroomPresenter }];

    this.popupService.open(
      PopupProductChooserComponent,
      {
        customValues: {
          products: JSON.stringify(products),
          title: 'SUPPORT_TICKET_PRODUCT',
        },
      },
      {
        selectedProduct: (selectedProduct: RenewsProduct) => {
          this.formId = (selectedProduct === RenewsProduct.ShowroomPresenter) ? 15 : 2;

          this.router.navigate(['/supporttickets/create', {
            locationPath: this.locationPath,
            formId: this.formId,
            playername: this.asClient(obj.item).playername,
            gssn: this.getTreeItemById(obj.parentId)?.item.gssn,
          }]);
        },
      },
    );
  }

  /**
   * Creates necessary parameters for URL and navigates to support-ticket-create.
   *
   * @param obj - The specific row from client list
   */
  public customRouterLink(obj: IOrgaentityTreeItem): void {
    let id = obj.parentId;
    let path = '';
    let iterations = 0;
    while (this.getTreeItemById(id)?.parentId) {
      path = id + '/' + path;
      // @ts-ignore
      id = this.getTreeItemById(id).parentId;
      if (++iterations > 99) {
        throw new Error('Infinite loop detected while creating support ticket link');
      }
    }
    this.locationPath += path;
    if (obj.item.type === 'CARSHOWADAPTER') {
      this.formId = 1;
      this.router.navigate(['/supporttickets/create', {
        locationPath: this.locationPath,
        formId: this.formId,
        name: obj.item.name,
        gssn: this.getTreeItemById(obj.parentId)?.item.gssn,
        id: obj.item.id,
      }]);
    } else if (obj.item.type === 'MATCHMAKER') {
      this.formId = 3;
      this.router.navigate(['/supporttickets/create', {
        locationPath: this.locationPath,
        formId: this.formId,
        name: obj.item.name,
        gssn: this.getTreeItemById(obj.parentId)?.item.gssn,
      }]);
    } else if (obj.item.type === 'HANDOVERCOMPANION') {
      this.formId = 6;
      this.router.navigate(['/supporttickets/create', {
        locationPath: this.locationPath,
        formId: this.formId,
        name: obj.item.name,
        gssn: this.getTreeItemById(obj.parentId)?.item.gssn,
      }]);
    } else if (obj.item.type === 'CARMEDIASTICK') {
      this.formId = 7;
      this.router.navigate(['/supporttickets/create', {
        locationPath: this.locationPath,
        formId: this.formId,
        hostname: this.asClient(obj.item).hostname,
        gssn: this.getTreeItemById(obj.parentId)?.item.gssn,
      }]);
    } else if (obj.item.type === 'SHOWROOMCONNECT') {
      this.formId = 9;
      this.router.navigate(['/supporttickets/create', {
        locationPath: this.locationPath,
        formId: this.formId,
        gssn: this.getTreeItemById(obj.parentId)?.item.gssn,
      }]);
    } else {
      this.openProductChooserPopup(obj);
    }
  }
}
